export const InfoBar = props => {
    return (
      <div className={props.info ? "visible" : "invisible"} id="info-bar">

        {`This shop is for the young and the young at heart.
        We carry a wide variety of colorful and cuddly plushies,
        perfect for anyone who wants a little bit of extra warmth
        and joy in their life. Our selection includes everything
        from your favorite furry animals to squishable creatures
        from outer space, so there's sure to be something for everyone.
        Plus, our prices are unbeatable, so you can indulge in as
        many cuddly friends as you'd like!`}

      </div>
    )
  }