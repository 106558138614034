import { useState, useRef } from 'react';
import logo from './images/logo-nobg.png';
import './App.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import { Button, InfoBar, Logo, Socials } from './components';
library.add(fab, fas);

/*

How to scroll to component
https://kbenbeneck.medium.com/using-scrollintoview-with-react-components-ba41df3ff12

*/

function App() {
  const [info, setInfo] = useState(false);

  const MainContent = props => {
    const handleInfoClick = () => {
      console.log('here');
      if (info == false) {
        setInfo(true);
      } else {
        setInfo(false);
      }
    }
    return (
      <div id="main-content">
        <Button name="shop" text="shop"/>
        <Button name="info" text="info" handleClick={handleInfoClick}/>
      </div>
    )
  }

  const UpperDiv = props => {
    return (
      <div id="upper-div">

      </div>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <UpperDiv/>
        <Logo
          logo={logo}
        />
        <Typewriter
          options={{
            strings: ['Squishies', 'Plushies', 'Stuffies', "Fluffies"],
            autoStart: true,
            loop: true,
          }}
        />
        <MainContent/>
        <InfoBar info={info}/>
        <Socials/>
      </header>
    </div>
  );
}

export default App;
